import './App.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
function Contact() {
  useEffect(() => {
    // Show preloader
    const preloader = document.getElementById('preloader');
    preloader.classList.remove('hide'); // Remove the 'hide' class to show the preloader
  
    // Simulate loading or add custom logic
    const timer = setTimeout(() => {
      preloader.classList.add('hide'); // Add the 'hide' class to hide the preloader
    }, 1000); // Adjust the time as needed for your loading effect
  
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);
  
  return (
<>
  {/* Preloader */}
  <div id="preloader">
    <div className="preloader-inner">
      <div className="spinner">
        <img src="./assets/wiraltribe_logo.png" alt="img" />
      </div>
    </div>
  </div>
  {/* pointer start */}
  <div className="pointer bnz-pointer" id="bnz-pointer" />
  {/* Main Header */}
  <header className="header">
      {/* Lower Bar */}
      <div className="header-inner">
        <div className="container-fluid pe-0">
          <div className="d-flex align-items-center justify-content-between">
            {/* Left Part */}
            <div className="header_left_part d-flex align-items-center">
              <div className="logo">
                <Link to="/" className="light_logo">
                  <img src="./assets/wiraltribe_logo.png" alt="logo" />
                </Link>
                <Link to="/" className="dark_logo">
                  <img src="./assets/wiraltribe_logo.png" alt="logo" />
                </Link>
              </div>
            </div>
            {/* Center Part */}
            <div className="header_center_part d-none d-xl-block">
              <div className="mainnav">
                <ul className="main-menu">
                  <li className="menu-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/About">About Us</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/Services">Services</Link>
                  </li>
                  <li className="menu-item">
                    <a href="#">Blog</a>
                  </li>
                  <li className="menu-item">
                    <Link to="/Contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Right Part */}
            <div className="header_right_part d-flex align-items-center">
              <div className="aside_open wptb-element">
                <div className="aside-open--inner">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="header_search wptb-element">
                <a
                  href="#"
                  className="modal_search_icon"
                  data-bs-toggle="modal"
                  data-bs-target="#modalSearch"
                >
                  <i className="bi bi-search"></i>
                </a>
              </div>
              <button
                type="button"
                className="mr_menu_toggle wptb-element d-xl-none"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  {/* End Main Header */}
  {/* Mobile Responsive Menu */}
  <div className="mr_menu" data-lenis-prevent="">
    <button type="button" className="mr_menu_close">
      <i className="bi bi-x-lg" />
    </button>
    <div className="logo" />{" "}
    {/* Keep this div empty. Logo will come here by JavaScript */}
    <h6>Menu</h6>
    <div className="mr_navmenu" />{" "}
    {/* Keep this div empty. Menu will come here by JavaScript */}
    <h6>Contact Us</h6>
    <div className="wptb-icon-box1 style2">
      <div className="wptb-item--inner flex-start">
        <div className="wptb-item--icon">
          <i className="bi bi-envelope" />
        </div>
        <div className="wptb-item--holder">
          <p className="wptb-item--description">
            <a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a>
          </p>
        </div>
      </div>
    </div>
    <div className="wptb-icon-box1 style2">
      <div className="wptb-item--inner flex-start">
        <div className="wptb-item--icon">
          <i className="bi bi-geo-alt" />
        </div>
        <div className="wptb-item--holder">
          <p className="wptb-item--description">
            <a href="#">mindspace raidurg</a>
          </p>
        </div>
      </div>
    </div>
    <div className="wptb-icon-box1 style2">
      <div className="wptb-item--inner flex-start">
        <div className="wptb-item--icon">
          <i className="bi bi-envelope" />
        </div>
        <div className="wptb-item--holder">
          <p className="wptb-item--description">
            <a href="tel:+91 7416661799">+91 7416661799</a>
          </p>
        </div>
      </div>
    </div>
    <h6>Find Our Page</h6>
    <div className="social-box">
      <ul>
        <li>
          <a href="#">
            <i className="bi bi-facebook" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bi bi-instagram" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bi bi-linkedin" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bi bi-behance" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bi bi-youtube" />
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div className="aside_info_wrapper" data-lenis-prevent="">
    <button className="aside_close">
      Close <i className="bi bi-x-lg" />
    </button>
    <div className="aside_info_inner">
      <h6>// Instagram</h6>
      <div className="insta-logo">
        <i className="bi bi-instagram" />
        Wiraltribe
      </div>
      <div className="wptb-instagram--gallery">
        <div className="wptb-item--inner d-flex align-items-center justify-content-center flex-wrap">
          <div className="wptb-item">
            <div className="wptb-item--image">
              <img src="./assets/assets/img/instagram/6.jpg" alt="img" />
            </div>
          </div>
          <div className="wptb-item">
            <div className="wptb-item--image">
              <img src="./assets/assets/img/instagram/7.jpg" alt="img" />
            </div>
          </div>
          <div className="wptb-item">
            <div className="wptb-item--image">
              <img src="./assets/assets/img/instagram/8.jpg" alt="img" />
            </div>
          </div>
          <div className="wptb-item">
            <div className="wptb-item--image">
              <img src="./assets/assets/img/instagram/9.jpg" alt="img" />
            </div>
          </div>
          <div className="wptb-item">
            <div className="wptb-item--image">
              <img src="./assets/assets/img/instagram/10.jpg" alt="img" />
            </div>
          </div>
          <div className="wptb-item">
            <div className="wptb-item--image">
              <img src="./assets/assets/img/instagram/11.jpg" alt="img" />
            </div>
          </div>
        </div>
      </div>
      <div className="wptb-icon-box1 style2">
        <div className="wptb-item--inner flex-start">
          <div className="wptb-item--icon">
            <i className="bi bi-envelope" />
          </div>
          <div className="wptb-item--holder">
            <p className="wptb-item--description">
              <a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a>
            </p>
          </div>
        </div>
      </div>
      <div className="wptb-icon-box1 style2">
        <div className="wptb-item--inner flex-start">
          <div className="wptb-item--icon">
            <i className="bi bi-geo-alt" />
          </div>
          <div className="wptb-item--holder">
            <p className="wptb-item--description">
              <a href="#">HITEC City, Hyderabad</a>
            </p>
          </div>
        </div>
      </div>
      <div className="wptb-icon-box1 style2">
        <div className="wptb-item--inner flex-start">
          <div className="wptb-item--icon">
            <i className="bi bi-envelope" />
          </div>
          <div className="wptb-item--holder">
            <p className="wptb-item--description">
              <a href="tel:+917416661799">+91 7416661799</a>
            </p>
          </div>
        </div>
      </div>
      <h6>// Follow Us</h6>
      <div className="social-box style-square">
        <ul>
          <li>
            <a href="#">
              <i className="bi bi-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-instagram" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-linkedin" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-behance" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-youtube" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  {/* Modal Search */}
  <div className="search-modal">
    <div className="modal fade" id="modalSearch">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="search_overlay">
            <form className="credential-form" method="post">
              <div className="form-group">
                <input
                  type="text"
                  name="search"
                  className="keyword form-control"
                  placeholder="Search Here"
                />
              </div>
              <button type="submit" className="btn-search">
                <span className="text-first">
                  {" "}
                  <i className="bi bi-arrow-right" />{" "}
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Main Wrapper*/}
  <main className="wrapper">
    {/* Page Header */}
    <div className="wptb-page-heading">
      <div
        className="wptb-item--inner"
        style={{
          backgroundImage: 'url("./assets/assets/img/background/page-header-bg-4.jpg")'
        }}
      >
        <div className="wptb-item-layer wptb-item-layer-one">
          <img src="./assets/assets/img/more/circle.png" alt="img" />
        </div>
        <h2 className="wptb-item--title">Contact Us</h2>
      </div>
    </div>
    <section className="wptb-contact-form style2">
      <div className="wptb-item-layer both-version">
        <img src="./assets/assets/img/more/texture-2.png" alt="" />
        <img src="./assets/assets/img/more/texture-2-light.png" alt="" />
      </div>
      <section className="wptb-office-address pd-bottom-100 mr-top-35">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="wptb-icon-box1 wow fadeInLeft">
                <div className="wptb-item--inner flex-start">
                  <div className="wptb-item--icon">
                    <i className="bi bi-globe" />
                  </div>
                  <div className="wptb-item--holder">
                    <h3 className="wptb-item--title">Our Website</h3>
                    <p className="wptb-item--description">www.wiraltribe.com</p>
                    <a href="#" className="wptb-item--link">
                      Visit Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 px-md-5">
              <div className="wptb-icon-box1 wow fadeInLeft">
                <div className="wptb-item--inner flex-start">
                  <div className="wptb-item--icon">
                    <i className="bi bi-phone" />
                  </div>
                  <div className="wptb-item--holder">
                    <h3 className="wptb-item--title">Contact Us</h3>
                    <p className="wptb-item--description">+91 7416661799</p>
                    <a href="tel:+91 7416661799" className="wptb-item--link">
                      Call Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="wptb-icon-box1 wow fadeInLeft">
                <div className="wptb-item--inner flex-start">
                  <div className="wptb-item--icon">
                    <i className="bi bi-geo-alt" />
                  </div>
                  <div className="wptb-item--holder">
                    <h3 className="wptb-item--title">Company Address</h3>
                    <p className="wptb-item--description">
                      HITEC City, Hyderabad
                    </p>
                    <a href="#" className="wptb-item--link">
                      View Map
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="wptb-form--wrapper no-bg">
          <div className="row">
            <div className="col-lg-5">
              <div className="wptb-heading-two pe-lg-5">
                <div className="wptb-item--inner">
                  <h1 className="wptb-item--title">
                    <span>Contact Us</span>
                  </h1>
                  <h1 className="wptb-item--title">
                    {" "}
                    Feel Free To Ask Us Anything{" "}
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <form className="wptb-form" action="#" method="post">
                <div className="wptb-form--inner">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 mb-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="E-mail"
                          required=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="number"
                          className="form-control"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 mb-4">
                      <div className="form-group">
                        <input
                          type="text"
                          name="companyname"
                          className="form-control"
                          placeholder="Companyname"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12 mb-4">
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <div className="wptb-item--button">
                        <button className="btn" type="submit">
                          <span className="btn-wrap">
                            <span className="text-first">Send Mail</span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  {/* Footer */}
  <footer
    className="footer style1 bg-image-2"
    style={{ backgroundImage: 'url("./assets/assets/img/background/bg-5.png")' }}
  >
    <div className="footer-top">
      <div className="container">
        <div className="footer--inner">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
              <div className="footer-widget">
                <div className="footer-nav">
                <ul>
                    <li className="menu-item">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <a href='#'>Blog</a>
                    </li>
                    <li className="menu-item">
                        <a href='#'>Gallery</a>
                    </li>
                    <li className="menu-item">
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 mb-5 mb-md-0 order-1 order-md-0">
              <div className="footer-widget text-center">
                <div className="logo mr-bottom-55">
                  <a href="index.html" className="">
                    <img src="./assets/wiraltribe_logo.png" alt="logo" />
                  </a>
                </div>
                <h6 className="widget-title">Address: </h6>
                <p>
                  {" "}
                  3rd floor, C9RP+6RJ Trendz Axis, Vittal Rao Nagar, HITEC City,
                  Hyderabad, Telangana 500081
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
              <div className="footer-widget text-md-end">
                <div className="footer-nav">
                  <ul>
                    <li className="menu-item">
                      <a href="#">
                        <b style={{ color: "#b90808" }}>Influencer Marketing</b>
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Brand Building</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Strategic Campaigns</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">TVC &amp; Corporate Films</a>
                    </li>
                    <li className="menu-item">
                      <a href="#" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Footer Bottom Part */}
    <div className="footer-bottom">
      <div className="container">
        <div className="footer-bottom-inner">
          <div className="copyright">
            <p>
              <a href="#">WiralTribe</a> Marketing All Rights Reserved
            </p>
          </div>
          <div className="social-box style-oval">
            <ul>
              <li>
                <a href="#" className="bi bi-facebook" />
              </li>
              <li>
                <a href="#" className="bi bi-instagram" />
              </li>
              <li>
                <a href="#" className="bi bi-linkedin" />
              </li>
              <li>
                <a href="#" className="bi bi-behance" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div className="totop">
    <a href="#">
      <i className="bi bi-chevron-up" />
    </a>
  </div>
</>

  );
}

export default Contact;

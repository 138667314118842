import './App.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function About() {

  useEffect(() => {
    // Show preloader
    const preloader = document.getElementById('preloader');
    preloader.classList.remove('hide'); // Remove the 'hide' class to show the preloader
  
    // Simulate loading or add custom logic
    const timer = setTimeout(() => {
      preloader.classList.add('hide'); // Add the 'hide' class to hide the preloader
    }, 1000); // Adjust the time as needed for your loading effect
  
    // Initialize WOW.js (make sure WOW.js is loaded and available)
  
    // Initialize Odometer (if using the odometer counter library)
    const odometerElements = document.querySelectorAll('.odometer');
    odometerElements.forEach((el) => {
      el.innerHTML = el.getAttribute('data-count'); // Initialize odometer value
    });
  
    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  
  }, []); // Empty dependency array ensures this effect runs only once on mount
  
  
  return (
    <>
    {/* Preloader */}
    <div id="preloader">
      <div className="preloader-inner">
        <div className="spinner">
          <img src="./assets/wiraltribe_logo.png" alt="img" />
        </div>
      </div>
    </div>
    {/* pointer start */}
    <div className="pointer bnz-pointer" id="bnz-pointer" />
    {/* Main Header */}
    <header className="header">
      {/* Lower Bar */}
      <div className="header-inner">
        <div className="container-fluid pe-0">
          <div className="d-flex align-items-center justify-content-between">
            {/* Left Part */}
            <div className="header_left_part d-flex align-items-center">
              <div className="logo">
                <Link to="/" className="light_logo">
                  <img src="./assets/wiraltribe_logo.png" alt="logo" />
                </Link>
                <Link to="/" className="dark_logo">
                  <img src="./assets/wiraltribe_logo.png" alt="logo" />
                </Link>
              </div>
            </div>
            {/* Center Part */}
            <div className="header_center_part d-none d-xl-block">
              <div className="mainnav">
                <ul className="main-menu">
                  <li className="menu-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/About">About Us</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/Services">Services</Link>
                  </li>
                  <li className="menu-item">
                    <a href="#">Blog</a>
                  </li>
                  <li className="menu-item">
                    <Link to="/Contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Right Part */}
            <div className="header_right_part d-flex align-items-center">
              <div className="aside_open wptb-element">
                <div className="aside-open--inner">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="header_search wptb-element">
                <a
                  href="#"
                  className="modal_search_icon"
                  data-bs-toggle="modal"
                  data-bs-target="#modalSearch"
                >
                  <i className="bi bi-search"></i>
                </a>
              </div>
              <button
                type="button"
                className="mr_menu_toggle wptb-element d-xl-none"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    {/* End Main Header */}
    {/* Mobile Responsive Menu */}
    <div className="mr_menu" data-lenis-prevent="">
      <button type="button" className="mr_menu_close">
        <i className="bi bi-x-lg" />
      </button>
      <div className="logo" />{" "}
      {/* Keep this div empty. Logo will come here by JavaScript */}
      <h6>Menu</h6>
      <div className="mr_navmenu" />{" "}
      {/* Keep this div empty. Menu will come here by JavaScript */}
      <h6>Contact Us</h6>
      <div className="wptb-icon-box1 style2">
        <div className="wptb-item--inner flex-start">
          <div className="wptb-item--icon">
            <i className="bi bi-envelope" />
          </div>
          <div className="wptb-item--holder">
            <p className="wptb-item--description">
              <a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a>
            </p>
          </div>
        </div>
      </div>
      <div className="wptb-icon-box1 style2">
        <div className="wptb-item--inner flex-start">
          <div className="wptb-item--icon">
            <i className="bi bi-geo-alt" />
          </div>
          <div className="wptb-item--holder">
            <p className="wptb-item--description">
              <a href="#">mindspace raidurg</a>
            </p>
          </div>
        </div>
      </div>
      <div className="wptb-icon-box1 style2">
        <div className="wptb-item--inner flex-start">
          <div className="wptb-item--icon">
            <i className="bi bi-envelope" />
          </div>
          <div className="wptb-item--holder">
            <p className="wptb-item--description">
              <a href="tel:+91 7416661799">+91 7416661799</a>
            </p>
          </div>
        </div>
      </div>
      <h6>Find Our Page</h6>
      <div className="social-box">
        <ul>
          <li>
            <a href="#">
              <i className="bi bi-facebook" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-instagram" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-linkedin" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-behance" />
            </a>
          </li>
          <li>
            <a href="#">
              <i className="bi bi-youtube" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="aside_info_wrapper" data-lenis-prevent="">
      <button className="aside_close">
        Close <i className="bi bi-x-lg" />
      </button>
      <div className="aside_info_inner">
        <h6>// Instagram</h6>
        <div className="insta-logo">
          <i className="bi bi-instagram" />
          Wiraltribe
        </div>
        <div className="wptb-instagram--gallery">
          <div className="wptb-item--inner d-flex align-items-center justify-content-center flex-wrap">
            <div className="wptb-item">
              <div className="wptb-item--image">
                <img src="./assets/assets/img/instagram/6.jpg" alt="img" />
              </div>
            </div>
            <div className="wptb-item">
              <div className="wptb-item--image">
                <img src="./assets/assets/img/instagram/7.jpg" alt="img" />
              </div>
            </div>
            <div className="wptb-item">
              <div className="wptb-item--image">
                <img src="./assets/assets/img/instagram/8.jpg" alt="img" />
              </div>
            </div>
            <div className="wptb-item">
              <div className="wptb-item--image">
                <img src="./assets/assets/img/instagram/9.jpg" alt="img" />
              </div>
            </div>
            <div className="wptb-item">
              <div className="wptb-item--image">
                <img src="./assets/assets/img/instagram/10.jpg" alt="img" />
              </div>
            </div>
            <div className="wptb-item">
              <div className="wptb-item--image">
                <img src="./assets/assets/img/instagram/11.jpg" alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className="wptb-icon-box1 style2">
          <div className="wptb-item--inner flex-start">
            <div className="wptb-item--icon">
              <i className="bi bi-envelope" />
            </div>
            <div className="wptb-item--holder">
              <p className="wptb-item--description">
                <a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="wptb-icon-box1 style2">
          <div className="wptb-item--inner flex-start">
            <div className="wptb-item--icon">
              <i className="bi bi-geo-alt" />
            </div>
            <div className="wptb-item--holder">
              <p className="wptb-item--description">
                <a href="#">HITEC City, Hyderabad</a>
              </p>
            </div>
          </div>
        </div>
        <div className="wptb-icon-box1 style2">
          <div className="wptb-item--inner flex-start">
            <div className="wptb-item--icon">
              <i className="bi bi-envelope" />
            </div>
            <div className="wptb-item--holder">
              <p className="wptb-item--description">
                <a href="tel:+91 7416661799">+91 7416661799</a>
              </p>
            </div>
          </div>
        </div>
        <h6>// Follow Us</h6>
        <div className="social-box style-square">
          <ul>
            <li>
              <a href="#">
                <i className="bi bi-facebook" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-instagram" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-linkedin" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-behance" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    {/* Modal Search */}
    <div className="search-modal">
      <div className="modal fade" id="modalSearch">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="search_overlay">
              <form className="credential-form" method="post">
                <div className="form-group">
                  <input
                    type="text"
                    name="search"
                    className="keyword form-control"
                    placeholder="Search Here"
                  />
                </div>
                <button type="submit" className="btn-search">
                  <span className="text-first">
                    {" "}
                    <i className="bi bi-arrow-right" />{" "}
                  </span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Main Wrapper*/}
    <main className="wrapper">
      {/* Page Header */}
      <div className="wptb-page-heading">
        <div
          className="wptb-item--inner"
          style={{
            backgroundImage: 'url("./assets/assets/img/background/page-header-bg-4.jpg")'
          }}
        >
          <div className="wptb-item-layer wptb-item-layer-one">
            <img src="./assets/assets/img/more/circle.png" alt="img" />
          </div>
          <h2 className="wptb-item--title">About Us</h2>
        </div>
      </div>
      <section
        className="wptb-about-one bg-image-4 pb-0"
        style={{
          backgroundImage: 'url("./assets/assets/img/more/texture.png")',
          backgroundPosition: "80%"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="row">
                <div className="col-md-6">
                  <div className="wptb-image-single wow fadeInUp">
                    <div className="wptb-item--inner">
                      <div className="wptb-item--image mt-3">
                        <img src="./assets/influencer marketing.png" alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ps-md-0 mt-5">
                  <div className="wptb-about--text">
                    <p className="wptb-about--text-one mb-4">
                      WiralTribe Agency runs wide and deep. Across many markets,
                      geographies &amp; typologies, our team members
                    </p>
                    <p>
                      The talent at WiralTribe runs wide range of services. Across
                      many markets, geographies &amp; typologies, our team members
                      are some of the finest people of photographers in the
                      industry wide and deep. From Across many markets,
                      geographies &amp; boundaries. Hire Wiraltribe in your
                      event.finest people of photographers in the industry wide
                      and deep. From Across many markets, geographies &amp;
                      boundaries. Hire Wiraltribe in your event
                    </p>
                    <div className="wptb-item--button round-button">
                      <a className="btn btn-two" href="#">
                        <span className="btn-wrap">
                          <span className="text-first">Explore Us</span>
                          <span className="text-second">
                            {" "}
                            <i className="bi bi-arrow-up-right" />{" "}
                            <i className="bi bi-arrow-up-right" />{" "}
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row wptb-about-funfact">
                <div className="col-md-6 mb-4 mb-md-0">
                  <div className="wptb-counter1 style1 pd-right-60 wow skewIn">
                    <div className="wptb-item--inner">
                      <div className="wptb-item--holder d-flex align-items-center">
                        <div className="wptb-item--value">
                          <span className="odometer" data-count={100} />
                          <span className="suffix">%</span>
                        </div>
                        <div className="wptb-item--text">
                          Customer Satisfaction
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="wptb-counter1 style1 pd-right-60 wow skewIn">
                    <div className="wptb-item--inner">
                      <div className="wptb-item--holder d-flex align-items-center">
                        <div className="wptb-item--value flex-shrink-0">
                          <span className="odometer" data-count={350} />
                          <span className="suffix">+</span>
                        </div>
                        <div className="wptb-item--text">Influencers</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 ps-xl-5 mt-5 mt-xl-0 d-none d-xl-block">
              <div className="wptb-image-single wow fadeInUp">
                <div className="wptb-item--inner">
                  <div className="wptb-item--image mt-3">
                    <img src="./assets/branding-1.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wptb-item-layer wptb-item-layer-one">
            <img src="./assets/assets/img/more/light-1.png" alt="img" />
          </div>
          <div className="wptb-item-layer wptb-item-layer-two both-version">
            <img src="./assets/assets/img/more/light-2.png" alt="img" />
            <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
          </div>
        </div>
      </section>
      {/* Text Marquee */}
      <div className="wptb-marquee">
        <div className="wptb-text-marquee1 wptb-slide-to-left">
          <div className="wptb-item--container">
            <div className="wptb-item--inner">
              <h4 className="wptb-item--text text-outline">
                <span className="wptb-text-backdrop">influencer</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text">
                <span className="wptb-text-backdrop">Wiraltribe</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text">
                <span className="wptb-text-backdrop">Marketing</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text text-outline">
                <span className="wptb-text-backdrop">Agency</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text">
                <span className="wptb-text-backdrop">Wiraltribe</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
            </div>
            <div className="wptb-item--inner">
              <h4 className="wptb-item--text text-outline">
                <span className="wptb-text-backdrop">influencer</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text">
                <span className="wptb-text-backdrop">Marketing</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text text-outline">
                <span className="wptb-text-backdrop">Agency</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
              <h4 className="wptb-item--text">
                <span className="wptb-text-backdrop">Wiraltribe</span>
                <span className="wptb-item-layer both-version position-relative">
                  <img src="./assets/assets/img/more/star.png" alt="img" />
                  <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      {/* Services */}
      <section
        className="wptb-services-one pd-bottom-80 bg-image-4"
        style={{
          backgroundImage: 'url("./assets/assets/img/more/texture-3.png")',
          backgroundPosition: "50% -16%"
        }}
      >
        <div className="container position-relative">
          <div className="row">
            {/* Iconbox */}
            <div className="col-md-6 pd-left-25 pd-right-25 wow fadeInLeft">
              <div className="wptb-icon-box7 mb-0">
                <div className="wptb-item--inner">
                  <div className="wptb-item--icon">
                    <img src="./assets/assets/img/our mission.png" alt="img" />
                  </div>
                  <div className="wptb-item--holder">
                    <h4 className="wptb-item--title">
                      <a href="#">Our Mission</a>
                    </h4>
                    <p className="wptb-item--description">
                      At WiralTribe, our mission is to revolutionize brand
                      storytelling by connecting businesses with authentic,
                      influential voices in the digital world. We leverage the
                      power of influencer marketing to help brands build genuine
                      relationships with their target audiences, drive engagement,
                      and foster long-term growth. By creating tailored campaigns
                      that resonate, inspire, and elevate brands, we aim to shape
                      the future of marketing with trust, creativity, and impact.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Iconbox */}
            <div className="col-md-6 pd-left-25 pd-right-25 wow fadeInLeft">
              <div className="wptb-icon-box7 mb-0 active highlight">
                <div className="wptb-item--inner">
                  <div className="wptb-item--icon">
                    <img src="./assets/assets/img/our vision.png" alt="img" />
                  </div>
                  <div className="wptb-item--holder">
                    <h4 className="wptb-item--title">
                      <a href="#">Our Vision</a>
                    </h4>
                    <p className="wptb-item--description">
                      Our vision is to be the global leader in influencer
                      marketing, empowering brands and creators to craft
                      meaningful connections that transcend traditional
                      advertising. We strive to create a world where every
                      campaign drives not just awareness, but genuine change,
                      where brands become a part of their audience's journey.
                      Through innovation, transparency, and a deep understanding
                      of digital culture, WiralTribe aims to be the catalyst for
                      the next wave of marketing excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid_lines">
            <div className="grid_line" />
            <div className="grid_line" />
            <div className="grid_line" />
            <div className="grid_line" />
          </div>
        </div>
      </section>
    </main>
    {/* Footer */}
    <footer
      className="footer style1 bg-image-2"
      style={{ backgroundImage: 'url("./assets/assets/img/background/bg-5.png")' }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="footer--inner">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                <div className="footer-widget">
                  <div className="footer-nav">
                  <ul>
                    <li className="menu-item">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <a href='#'>Blog</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Gallery</a>
                    </li>
                    <li className="menu-item">
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-5 mb-md-0 order-1 order-md-0">
                <div className="footer-widget text-center">
                  <div className="logo mr-bottom-55">
                    <a href="index.html" className="">
                      <img src="./assets/wiraltribe_logo.png" alt="logo" />
                    </a>
                  </div>
                  <h6 className="widget-title">Address: </h6>
                  <p>
                    {" "}
                    3rd floor, C9RP+6RJ Trendz Axis, Vittal Rao Nagar, HITEC City,
                    Hyderabad, Telangana 500081
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                <div className="footer-widget text-md-end">
                  <div className="footer-nav">
                    <ul>
                      <li className="menu-item">
                        <a href="#">
                          <b style={{ color: "#b90808" }}>Influencer Marketing</b>
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Brand Building</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Strategic Campaigns</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">TVC &amp; Corporate Films</a>
                      </li>
                      <li className="menu-item">
                        <a href="#" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom Part */}
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-inner">
            <div className="copyright">
              <p>
                <a href="#">WiralTribe</a> Marketing All Rights Reserved
              </p>
            </div>
            <div className="social-box style-oval">
              <ul>
                <li>
                  <a href="#" className="bi bi-facebook" />
                </li>
                <li>
                  <a href="#" className="bi bi-instagram" />
                </li>
                <li>
                  <a href="#" className="bi bi-linkedin" />
                </li>
                <li>
                  <a href="#" className="bi bi-behance" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div className="totop">
      <a href="#">
        <i className="bi bi-chevron-up" />
      </a>
    </div>
  </>
  
  );
}

export default About;

import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
function Services() {
  useEffect(() => {
    // Show preloader
    const preloader = document.getElementById('preloader');
    preloader.classList.remove('hide'); // Remove the 'hide' class to show the preloader
  
    // Simulate loading or add custom logic
    const timer = setTimeout(() => {
      preloader.classList.add('hide'); // Add the 'hide' class to hide the preloader
    }, 1000); // Adjust the time as needed for your loading effect
  
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);
  
  return (
    <>
      {/* Preloader */}
      <div id="preloader">
        <div className="preloader-inner">
          <div className="spinner">
            <img src="./assets/wiraltribe_logo.png" alt="img" />
          </div>
        </div>
      </div>
      {/* pointer start */}
      <div className="pointer bnz-pointer" id="bnz-pointer" />
      {/* Main Header */}
      <header className="header">
        {/* Lower Bar */}
        <div className="header-inner">
          <div className="container-fluid pe-0">
            <div className="d-flex align-items-center justify-content-between">
              {/* Left Part */}
              <div className="header_left_part d-flex align-items-center">
                <div className="logo">
                  <Link to="/" className="light_logo">
                    <img src="./assets/wiraltribe_logo.png" alt="logo" />
                  </Link>
                  <Link to="/" className="dark_logo">
                    <img src="./assets/wiraltribe_logo.png" alt="logo" />
                  </Link>
                </div>
              </div>
              {/* Center Part */}
              <div className="header_center_part d-none d-xl-block">
                <div className="mainnav">
                  <ul className="main-menu">
                    <li className="menu-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/Services">Services</Link>
                    </li>
                    <li className="menu-item">
                      <a href="#">Blog</a>
                    </li>
                    <li className="menu-item">
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Right Part */}
              <div className="header_right_part d-flex align-items-center">
                <div className="aside_open wptb-element">
                  <div className="aside-open--inner">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div className="header_search wptb-element">
                  <a
                    href="#"
                    className="modal_search_icon"
                    data-bs-toggle="modal"
                    data-bs-target="#modalSearch"
                  >
                    <i className="bi bi-search"></i>
                  </a>
                </div>
                <button
                  type="button"
                  className="mr_menu_toggle wptb-element d-xl-none"
                >
                  <i className="bi bi-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* End Main Header */}
      {/* Mobile Responsive Menu */}
      <div className="mr_menu" data-lenis-prevent="">
        <button type="button" className="mr_menu_close">
          <i className="bi bi-x-lg" />
        </button>
        <div className="logo" />{" "}
        {/* Keep this div empty. Logo will come here by JavaScript */}
        <h6>Menu</h6>
        <div className="mr_navmenu" />{" "}
        {/* Keep this div empty. Menu will come here by JavaScript */}
        <h6>Contact Us</h6>
        <div className="wptb-icon-box1 style2">
          <div className="wptb-item--inner flex-start">
            <div className="wptb-item--icon">
              <i className="bi bi-envelope" />
            </div>
            <div className="wptb-item--holder">
              <p className="wptb-item--description">
                <a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a>
              </p>
            </div>
          </div>
        </div>
        <div className="wptb-icon-box1 style2">
          <div className="wptb-item--inner flex-start">
            <div className="wptb-item--icon">
              <i className="bi bi-geo-alt" />
            </div>
            <div className="wptb-item--holder">
              <p className="wptb-item--description">
                <a href="#">mindspace raidurg</a>
              </p>
            </div>
          </div>
        </div>
        <div className="wptb-icon-box1 style2">
          <div className="wptb-item--inner flex-start">
            <div className="wptb-item--icon">
              <i className="bi bi-envelope" />
            </div>
            <div className="wptb-item--holder">
              <p className="wptb-item--description">
                <a href="tel:+917416661799">+91 7416661799</a>
              </p>
            </div>
          </div>
        </div>
        <h6>Find Our Page</h6>
        <div className="social-box">
          <ul>
            <li>
              <a href="#">
                <i className="bi bi-facebook" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-instagram" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-linkedin" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-behance" />
              </a>
            </li>
            <li>
              <a href="#">
                <i className="bi bi-youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="aside_info_wrapper" data-lenis-prevent="">
        <button className="aside_close">
          Close <i className="bi bi-x-lg" />
        </button>
        <div className="aside_info_inner">
          <h6>// Instagram</h6>
          <div className="insta-logo">
            <i className="bi bi-instagram" />
            Wiraltribe
          </div>
          <div className="wptb-instagram--gallery">
            <div className="wptb-item--inner d-flex align-items-center justify-content-center flex-wrap">
              <div className="wptb-item">
                <div className="wptb-item--image">
                  <img src="./assets/assets/img/instagram/6.jpg" alt="img" />
                </div>
              </div>
              <div className="wptb-item">
                <div className="wptb-item--image">
                  <img src="./assets/assets/img/instagram/7.jpg" alt="img" />
                </div>
              </div>
              <div className="wptb-item">
                <div className="wptb-item--image">
                  <img src="./assets/assets/img/instagram/8.jpg" alt="img" />
                </div>
              </div>
              <div className="wptb-item">
                <div className="wptb-item--image">
                  <img src="./assets/assets/img/instagram/9.jpg" alt="img" />
                </div>
              </div>
              <div className="wptb-item">
                <div className="wptb-item--image">
                  <img src="./assets/assets/img/instagram/10.jpg" alt="img" />
                </div>
              </div>
              <div className="wptb-item">
                <div className="wptb-item--image">
                  <img src="./assets/assets/img/instagram/11.jpg" alt="img" />
                </div>
              </div>
            </div>
          </div>
          <div className="wptb-icon-box1 style2">
            <div className="wptb-item--inner flex-start">
              <div className="wptb-item--icon">
                <i className="bi bi-envelope" />
              </div>
              <div className="wptb-item--holder">
                <p className="wptb-item--description">
                  <a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a>
                </p>
              </div>
            </div>
          </div>
          <div className="wptb-icon-box1 style2">
            <div className="wptb-item--inner flex-start">
              <div className="wptb-item--icon">
                <i className="bi bi-geo-alt" />
              </div>
              <div className="wptb-item--holder">
                <p className="wptb-item--description">
                  <a href="#">HITEC City, Hyderabad</a>
                </p>
              </div>
            </div>
          </div>
          <div className="wptb-icon-box1 style2">
            <div className="wptb-item--inner flex-start">
              <div className="wptb-item--icon">
                <i className="bi bi-envelope" />
              </div>
              <div className="wptb-item--holder">
                <p className="wptb-item--description">
                  <a href="tel:+917416661799">+91 7416661799</a>
                </p>
              </div>
            </div>
          </div>
          <h6>// Follow Us</h6>
          <div className="social-box style-square">
            <ul>
              <li>
                <a href="#">
                  <i className="bi bi-facebook" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-linkedin" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-behance" />
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Modal Search */}
      <div className="search-modal">
        <div className="modal fade" id="modalSearch">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="search_overlay">
                <form className="credential-form" method="post">
                  <div className="form-group">
                    <input
                      type="text"
                      name="search"
                      className="keyword form-control"
                      placeholder="Search Here"
                    />
                  </div>
                  <button type="submit" className="btn-search">
                    <span className="text-first">
                      {" "}
                      <i className="bi bi-arrow-right" />{" "}
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Main Wrapper*/}
      <main className="wrapper">
        {/* Page Header */}
        <div className="wptb-page-heading">
          <div
            className="wptb-item--inner"
            style={{
              backgroundImage: 'url("./assets/assets/img/background/page-header-bg-4.jpg")'
            }}
          >
            <div className="wptb-item-layer wptb-item-layer-one">
              <img src="./assets/assets/img/more/circle.png" alt="img" />
            </div>
            <h2 className="wptb-item--title">Services</h2>
          </div>
        </div>
        {/* Text Marquee */}
        <div className="wptb-marquee">
          <div className="wptb-text-marquee1 wptb-slide-to-left">
            <div className="wptb-item--container">
              <div className="wptb-item--inner">
                <h4 className="wptb-item--text text-outline">
                  <span className="wptb-text-backdrop">influencer</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text">
                  <span className="wptb-text-backdrop">Wiraltribe</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text">
                  <span className="wptb-text-backdrop">Marketing</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text text-outline">
                  <span className="wptb-text-backdrop">Agency</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text">
                  <span className="wptb-text-backdrop">Wiraltribe</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
              </div>
              <div className="wptb-item--inner">
                <h4 className="wptb-item--text text-outline">
                  <span className="wptb-text-backdrop">influencer</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text">
                  <span className="wptb-text-backdrop">Marketing</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text text-outline">
                  <span className="wptb-text-backdrop">Agency</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
                <h4 className="wptb-item--text">
                  <span className="wptb-text-backdrop">Wiraltribe</span>
                  <span className="wptb-item-layer both-version position-relative">
                    <img src="./assets/assets/img/more/star.png" alt="img" />
                    <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <section className="wptb-about-two bg-transparent pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ps-md-5 mt-4 mt-md-0">
                <div className="wptb-about--text ps-md-5">
                  <h3>Brand Building &amp; Positioning </h3>
                  <p>
                    Brand building and positioning are critical components of a
                    successful marketing strategy. Brand building involves creating
                    a unique identity and image for a product or service through
                    consistent messaging, quality offerings, and customer
                    engagement. This process includes defining the brand's values,
                    mission, and vision, which collectively form the brand's
                    personality. Effective brand building fosters brand awareness,
                    loyalty, and trust, distinguishing the brand from competitors.
                  </p>
                  <p>
                    {" "}
                    Positioning, on the other hand, is about strategically placing
                    the brand in the minds of the target audience. This involves
                    identifying a unique selling proposition (USP) and communicating
                    it clearly to ensure the brand is perceived as distinct and
                    valuable.
                  </p>{" "}
                </div>
              </div>
              <div className="col-md-6">
                <div className="wptb-image-single wow fadeInUp">
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image position-relative">
                      <img
                        src="./assets/assets/img/services_images/branding.png"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="wptb-item-layer wptb-item-layer-one both-version">
                    <img src="./assets/assets/img/more/light-2.png" alt="img" />
                    <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wptb-about-two bg-transparent pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="wptb-image-single wow fadeInUp">
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image position-relative">
                      <img
                        src="./assets/assets/img/services_images/advertising.png"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="wptb-item-layer wptb-item-layer-one both-version">
                    <img src="./assets/assets/img/more/light-2.png" alt="img" />
                    <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 ps-md-5 mt-4 mt-md-0">
                <div className="wptb-about--text ps-md-5">
                  <h3>Advertising</h3>
                  <p>
                    Advertising in influencer marketing leverages the credibility
                    and reach of social media influencers to promote products or
                    services. Influencers, who have cultivated substantial
                    followings and trust within specific niches, act as brand
                    advocates, sharing their authentic experiences and endorsements
                    with their audiences. This form of advertising is particularly
                    effective because it combines the persuasive power of
                    word-of-mouth with the extensive reach of digital platforms.
                  </p>
                  <p>
                    {" "}
                    Influencer marketing campaigns often involve collaborations
                    where influencers create content that highlights the benefits
                    and features of a brand, seamlessly integrating the product into
                    their lifestyle
                  </p>
                </div>
                <p />
              </div>
            </div>
          </div>
        </section>
        <section className="wptb-about-two bg-transparent pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ps-md-5 mt-4 mt-md-0">
                <div className="wptb-about--text ps-md-5">
                  <h3>Social Campaigns</h3>
                  <p>
                    Social campaigns in influencer marketing harness the power of
                    influencers to drive awareness and engagement around social
                    issues, causes, or brand missions. These campaigns are designed
                    to leverage the authentic voice and widespread reach of
                    influencers to promote positive change and raise awareness among
                    their followers. By partnering with influencers who share a
                    genuine interest in the cause, brands can ensure that the
                    message resonates deeply with the target audience.
                  </p>
                  <p>
                    These social campaigns often include compelling narratives,
                    impactful visuals, and calls to action that encourage followers
                    to participate, share, and support the cause. The relatability
                    and trustworthiness of influencers add credibility to the
                    campaign, making it more likely to inspire real action and
                    community involvement
                  </p>
                </div>
                <p />
              </div>
              <div className="col-md-6">
                <div className="wptb-image-single wow fadeInUp">
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image position-relative">
                      <img
                        src="./assets/assets/img/services_images/social media marketing.png"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="wptb-item-layer wptb-item-layer-one both-version">
                    <img src="./assets/assets/img/more/light-2.png" alt="img" />
                    <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* digital marketing */}

        <section className="wptb-about-two bg-transparent pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="wptb-image-single wow fadeInUp">
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image position-relative">
                      <img
                        src="./assets/assets/img/services_images/influencer_marketing.png"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="wptb-item-layer wptb-item-layer-one both-version">
                    <img src="./assets/assets/img/more/light-2.png" alt="img" />
                    <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 ps-md-5 mt-4 mt-md-0">
                <div className="wptb-about--text ps-md-5">
                  <h3>Influencer Marketing </h3>
                  <p>
                    Influencer marketing is a strategic approach that leverages the
                    reach and credibility of individuals with significant social
                    media followings to promote products, services, or brands. By
                    collaborating with influencers who have built trust and loyalty
                    within specific niches, brands can effectively tap into engaged
                    audiences that value the influencers' opinions and
                    recommendations.
                  </p>
                  <p>
                    {" "}
                    Influencer marketing campaigns typically involve influencers
                    creating authentic content that showcases the brand in a
                    relatable and engaging manner, whether through posts, stories,
                    videos, or blogs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="wptb-about-two bg-transparent pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6 ps-md-5 mt-4 mt-md-0">
                <div className="wptb-about--text ps-md-5">
                  <h3>TVC &amp; Corporate Films</h3>
                  <p>
                    Television commercials (TVC) and corporate films play a
                    significant role in influencer marketing by enhancing brand
                    storytelling and expanding audience reach. TVCs, with their wide
                    broadcast reach, allow brands to deliver impactful, visually
                    engaging messages that can capture the attention of a broad
                    audience.
                  </p>
                  <p>
                    {" "}
                    When influencers are featured in these commercials, their
                    established credibility and familiarity with their followers can
                    significantly amplify the message's effectiveness.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wptb-image-single wow fadeInUp">
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image position-relative">
                      <img
                        src="./assets/assets/img/services_images/tvc films.png"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="wptb-item-layer wptb-item-layer-one both-version">
                    <img src="./assets/assets/img/more/light-2.png" alt="img" />
                    <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* influencer */}
      


        <section className="wptb-about-two bg-transparent pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="wptb-image-single wow fadeInUp">
                  <div className="wptb-item--inner">
                    <div className="wptb-item--image position-relative">
                      <img
                        src="./assets/assets/img/services_images/digital marketing.png"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="wptb-item-layer wptb-item-layer-one both-version">
                    <img src="./assets/assets/img/more/light-2.png" alt="img" />
                    <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 ps-md-5 mt-4 mt-md-0">
                <div className="wptb-about--text ps-md-5">
                  <h3>Digital Marketing Services</h3>
                  <p>
                    Digital marketing services for influencer marketing encompass a
                    range of strategic activities designed to optimize the impact of
                    influencer collaborations. These services include identifying
                    and vetting suitable influencers whose audience demographics
                    align with the brand's target market, thereby ensuring relevance
                    and authenticity. Digital marketing agencies use sophisticated
                    tools and analytics to track influencer performance, engagement
                    rates, and return on investment, providing valuable insights for
                    refining campaigns.
                  </p>
                  <p>
                    {" "}
                    They also manage content creation and distribution, ensuring
                    that the brand message is seamlessly integrated into the
                    influencer's content in a way that feels natural and engaging
                  </p>
                </div>
                <p />
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* Footer */}
      <footer
        className="footer style1 bg-image-2"
        style={{ backgroundImage: 'url("./assets/assets/img/background/bg-5.png")' }}
      >
        <div className="footer-top">
          <div className="container">
            <div className="footer--inner">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                  <div className="footer-widget">
                    <div className="footer-nav">
                    <ul>
                    <li className="menu-item">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <a href='#'>Blog</a>
                    </li>
                    <li className="menu-item">
                        <a href='#'>Gallery</a>
                    </li>
                    <li className="menu-item">
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 mb-5 mb-md-0 order-1 order-md-0">
                  <div className="footer-widget text-center">
                    <div className="logo mr-bottom-55">
                      <a href="index.html" className="">
                        <img src="./assets/wiraltribe_logo.png" alt="logo" />
                      </a>
                    </div>
                    <h6 className="widget-title">Address: </h6>
                    <p>
                      {" "}
                      3rd floor, C9RP+6RJ Trendz Axis, Vittal Rao Nagar, HITEC City,
                      Hyderabad, Telangana 500081
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                  <div className="footer-widget text-md-end">
                    <div className="footer-nav">
                      <ul>
                        <li className="menu-item">
                          <a href="#">
                            <b style={{ color: "#b90808" }}>Influencer Marketing</b>
                          </a>
                        </li>
                        <li className="menu-item">
                          <a href="#">Brand Building</a>
                        </li>
                        <li className="menu-item">
                          <a href="#">Strategic Campaigns</a>
                        </li>
                        <li className="menu-item">
                          <a href="#">TVC &amp; Corporate Films</a>
                        </li>
                        <li className="menu-item">
                          <a href="#" />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom Part */}
        <div className="footer-bottom">
          <div className="container">
            <div className="footer-bottom-inner">
              <div className="copyright">
                <p>
                  <a href="#">WiralTribe</a> Marketing All Rights Reserved
                </p>
              </div>
              <div className="social-box style-oval">
                <ul>
                  <li>
                    <a href="#" className="bi bi-facebook" />
                  </li>
                  <li>
                    <a href="#" className="bi bi-instagram" />
                  </li>
                  <li>
                    <a href="#" className="bi bi-linkedin" />
                  </li>
                  <li>
                    <a href="#" className="bi bi-behance" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="totop">
        <a href="#">
          <i className="bi bi-chevron-up" />
        </a>
      </div>
    </>

  );
}

export default Services;

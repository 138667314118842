import React, { useEffect } from 'react';
import 'swiper/swiper-bundle.css';
import { useLocation } from 'react-router-dom';  // Import useLocation
import './App.css';
import { Link } from 'react-router-dom';
import Swiper from 'swiper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Show preloader
    const preloader = document.getElementById('preloader');
    preloader.classList.remove('hide'); // Remove the 'hide' class to show the preloader
  
    // Simulate loading or add custom logic
    const timer = setTimeout(() => {
      preloader.classList.add('hide'); // Add the 'hide' class to hide the preloader
    }, 1000); // Adjust the time as needed for your loading effect
  
    // Initialize WOW.js (make sure WOW.js is loaded and available)
  
    // Initialize Odometer (if using the odometer counter library)
    const odometerElements = document.querySelectorAll('.odometer');
    odometerElements.forEach((el) => {
      el.innerHTML = el.getAttribute('data-count'); // Initialize odometer value
    });
  
    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  
  }, []); // Empty dependency array ensures this effect runs only once on mount

  useEffect(() => {
    // Initialize Swiper when Home page is rendered
    const swiper = new Swiper('.wptb-swiper-slider-two', {
      loop: true,
      autoplay: {
        delay: 2500, // Delay between slides
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    // Cleanup swiper when component unmounts or re-renders
    return () => {
      swiper.destroy(true, true);
    };
  }, [location]); // Trigger on location change

  return (
        <div>

            {/* <!-- Preloader --> */}
            <div id="preloader">
                <div className="preloader-inner">
                    <div className="spinner">
                        <img src="./assets/wiraltribe_logo.png" alt="img" />
                    </div>
                </div>
            </div>

            {/* <!-- pointer start --> */}
            <div className="pointer bnz-pointer" id="bnz-pointer"></div>



            {/* <!-- Main Header --> */}
            <header className="header">
      {/* Lower Bar */}
      <div className="header-inner">
        <div className="container-fluid pe-0">
          <div className="d-flex align-items-center justify-content-between">
            {/* Left Part */}
            <div className="header_left_part d-flex align-items-center">
              <div className="logo">
                <Link to="/" className="light_logo">
                  <img src="./assets/wiraltribe_logo.png" alt="logo" />
                </Link>
                <Link to="/" className="dark_logo">
                  <img src="./assets/wiraltribe_logo.png" alt="logo" />
                </Link>
              </div>
            </div>
            {/* Center Part */}
            <div className="header_center_part d-none d-xl-block">
              <div className="mainnav">
                <ul className="main-menu">
                  <li className="menu-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/About">About Us</Link>
                  </li>
                  <li className="menu-item">
                    <Link to="/Services">Services</Link>
                  </li>
                  <li className="menu-item">
                    <a href="#">Blog</a>
                  </li>
                  <li className="menu-item">
                    <Link to="/Contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* Right Part */}
            <div className="header_right_part d-flex align-items-center">
              <div className="aside_open wptb-element">
                <div className="aside-open--inner">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="header_search wptb-element">
                <a
                  href="#"
                  className="modal_search_icon"
                  data-bs-toggle="modal"
                  data-bs-target="#modalSearch"
                >
                  <i className="bi bi-search"></i>
                </a>
              </div>
              <button
                type="button"
                className="mr_menu_toggle wptb-element d-xl-none"
              >
                <i className="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
            {/* <!-- End Main Header -->		 */}

            {/* <!-- Mobile Res/ponsive Menu --> */}
            <div className="mr_menu" data-lenis-prevent>
                <button type="button" className="mr_menu_close"><i className="bi bi-x-lg"></i></button>
                <div className="logo"></div>
                {/* <!-- Keep this div empty. Logo will come here by JavaScript --> */}

                <h6>Menu</h6>
                <div className="mr_navmenu"></div>
                {/* <!-- Keep this div empty. Menu will come here by JavaScript --> */}

                <h6>Contact Us</h6>
                <div className="wptb-icon-box1 style2">
                    <div className="wptb-item--inner flex-start">
                        <div className="wptb-item--icon"><i className="bi bi-envelope"></i></div>
                        <div className="wptb-item--holder">
                            <p className="wptb-item--description"><a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a></p>
                        </div>
                    </div>
                </div>

                <div className="wptb-icon-box1 style2">
                    <div className="wptb-item--inner flex-start">
                        <div className="wptb-item--icon"><i className="bi bi-geo-alt"></i></div>
                        <div className="wptb-item--holder">
                            <p className="wptb-item--description"><a href="#">mindspace raidurg</a></p>
                        </div>
                    </div>
                </div>

                <div className="wptb-icon-box1 style2">
                    <div className="wptb-item--inner flex-start">
                        <div className="wptb-item--icon"><i className="bi bi-envelope"></i></div>
                        <div className="wptb-item--holder">
                            <p className="wptb-item--description"><a href="tel:+917416661799">+91 7416661799</a></p>
                        </div>
                    </div>
                </div>

                <h6>Find Our Page</h6>
                <div className="social-box">
                    <ul>
                        <li><a href="#"><i className="bi bi-facebook"></i></a></li>
                        <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                        <li><a href="#"><i className="bi bi-linkedin"></i></a></li>
                        <li><a href="#"><i className="bi bi-behance"></i></a></li>
                        <li><a href="#"><i className="bi bi-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div className="aside_info_wrapper" data-lenis-prevent>
                <button className="aside_close">Close <i className="bi bi-x-lg"></i></button>
                <div className="aside_info_inner">
                    <h6>// Instagram</h6>
                    <div className="insta-logo">
                        <i className="bi bi-instagram"></i>Wiraltribe
                    </div>
                    <div className="wptb-instagram--gallery">
                        <div className="wptb-item--inner d-flex align-items-center justify-content-center flex-wrap">
                            <div className="wptb-item">
                                <div className="wptb-item--image">
                                    <img src="./assets/assets/img/instagram/6.jpg" alt="img" />
                                </div>
                            </div>

                            <div className="wptb-item">
                                <div className="wptb-item--image">
                                    <img src="./assets/assets/img/instagram/7.jpg" alt="img" />
                                </div>
                            </div>

                            <div className="wptb-item">
                                <div className="wptb-item--image">
                                    <img src="./assets/assets/img/instagram/8.jpg" alt="img" />
                                </div>
                            </div>

                            <div className="wptb-item">
                                <div className="wptb-item--image">
                                    <img src="./assets/assets/img/instagram/9.jpg" alt="img" />
                                </div>
                            </div>

                            <div className="wptb-item">
                                <div className="wptb-item--image">
                                    <img src="./assets/assets/img/instagram/10.jpg" alt="img" />
                                </div>
                            </div>

                            <div className="wptb-item">
                                <div className="wptb-item--image">
                                    <img src="./assets/assets/img/instagram/11.jpg" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="wptb-icon-box1 style2">
                        <div className="wptb-item--inner flex-start">
                            <div className="wptb-item--icon"><i className="bi bi-envelope"></i></div>
                            <div className="wptb-item--holder">
                                <p className="wptb-item--description"><a href="mailto:contact@wiraltribe.com">contact@wiraltribe.com</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="wptb-icon-box1 style2">
                        <div className="wptb-item--inner flex-start">
                            <div className="wptb-item--icon"><i className="bi bi-geo-alt"></i></div>
                            <div className="wptb-item--holder">
                                <p className="wptb-item--description"><a href="#">HITEC City, Hyderabad</a></p>
                            </div>
                        </div>
                    </div>

                    <div className="wptb-icon-box1 style2">
                        <div className="wptb-item--inner flex-start">
                            <div className="wptb-item--icon"><i className="bi bi-envelope"></i></div>
                            <div className="wptb-item--holder">
                                <p className="wptb-item--description"><a href="tel:+917416661799">+91 7416661799</a></p>
                            </div>
                        </div>
                    </div>

                    <h6>// Follow Us</h6>
                    <div className="social-box style-square">
                        <ul>
                            <li><a href="#"><i className="bi bi-facebook"></i></a></li>
                            <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                            <li><a href="#"><i className="bi bi-linkedin"></i></a></li>
                            <li><a href="#"><i className="bi bi-behance"></i></a></li>
                            <li><a href="#"><i className="bi bi-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* <!-- Modal Search --> */}
            <div className="search-modal">
                <div className="modal fade" id="modalSearch">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="search_overlay">
                                <form className="credential-form" method="post">
                                    <div className="form-group">
                                        <input type="text" name="search" className="keyword form-control" placeholder="Search Here" />
                                    </div>
                                    <button type="submit" className="btn-search">
                                        <span className="text-first"> <i className="bi bi-arrow-right"></i> </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Main Wrapper--> */}
            <main className="wrapper">
                {/* <!-- Slider Section --> */}
                <section className="wptb-slider style2">
                    <div className="swiper-container wptb-swiper-slider-two">
                        <div className="swiper-wrapper">

                            {/* <!-- Slide Item --> */}
                            <div className="swiper-slide">
                                <div className="wptb-slider--item">
                                    <div className="wptb-slider--image"    style={{ backgroundImage: "url('./assets/assets/img/slider/5.jpg')" }}></div>
                                    <div className="wptb-slider--inner">

                                        <div className="wptb-item-layer wptb-item-layer-one">
                                            <img src="./assets/assets/img/slider/layer-3.png" alt="img" />
                                        </div>
                                        <div className="wptb-heading">
                                            <div className="wptb-item--inner">
                                                <h3 className="wptb-item--title">Influencer Marketing</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Slide Item --> */}

                            {/* <!-- Slide Item --> */}
                            <div className="swiper-slide">
                                <div className="wptb-slider--item">
                                    <div className="wptb-slider--image" style={{backgroundImage:" url('./assets/assets/img/slider/11.jpg')"}}></div>
                                    <div className="wptb-slider--inner">
                                        <div className="wptb-item-layer wptb-item-layer-one">
                                            <img src="./assets/assets/img/slider/layer-3.png" alt="img" />
                                        </div>
                                        <div className="wptb-heading">
                                            <div className="wptb-item--inner">
                                                <h3 className="wptb-item--title">Brand Building & Positioning </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Slide Item --> */}


                            {/* <!-- Slide Item --> */}
                            <div className="swiper-slide">
                                <div className="wptb-slider--item">
                                    <div className="wptb-slider--image" style={{backgroundImage:" url('./assets/assets/img/slider/31.jpg')"}}></div>
                                    <div className="wptb-slider--inner">
                                        <div className="wptb-item-layer wptb-item-layer-one">
                                            <img src="./assets/assets/img/slider/layer-3.png" alt="img" />
                                        </div>
                                        <div className="wptb-heading">
                                            <div className="wptb-item--inner">
                                                <h3 className="wptb-item--title">Strategic Campaign</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Slide Item --> */}
                            {/* <!-- Slide Item --> */}
                            <div className="swiper-slide">
                                <div className="wptb-slider--item">
                                    <div className="wptb-slider--image" style={{backgroundImage:" url('./assets/assets/img/slider/44.jpg')"}}></div>
                                    <div className="wptb-slider--inner">
                                        <div className="wptb-item-layer wptb-item-layer-one">
                                            <img src="./assets/assets/img/slider/layer-3.png" alt="img" />
                                        </div>
                                        <div className="wptb-heading">
                                            <div className="wptb-item--inner">
                                                <h3 className="wptb-item--title">TVC & Corporate Films</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- End Slide Item --> */}
                        </div>
                    </div>

                    {/* <!-- Left Pane --> */}
                    <div className="wptb-left-pane justify-content-center">
                        <div className="logo">
                            <h5>Connecting Creatives</h5>

                        </div>
                    </div>

                    {/* <!-- Right Pane --> */}
                    <div className="wptb-right-pane justify-content-center">
                        <div className="logo">
                        <h5
  style={{
    writingMode: "vertical-rl", // Vertical text layout
    transform: "rotate(90deg)", // Flip the text to face left
    textAlign: "center", // Center-align the text
  }}
>
  Amplifying voices
</h5>

                        </div>
                    </div>

                    {/* <!-- Bottom Pane --> */}
                    <div className="wptb-bottom-pane justify-content-center">
                        {/* <!-- pagination dots --> */}
                        <div className="wptb-swiper-dots style2">
                            <div className="swiper-pagination"></div>
                        </div>

                        {/* <!-- Swiper Navigation --> */}
                        <div className="wptb-swiper-navigation style3">
                            <div className="wptb-swiper-arrow swiper-button-prev"></div>
                            <div className="wptb-swiper-arrow swiper-button-next"></div>
                        </div>
                    </div>

                </section>

                <section className="wptb-about-one bg-image-4 pb-0" style={{backgroundImage: "url('./assets/assets/img/more/texture.png')", backgroundPosition:" 80%"}}>
                    <div className="container">
                        <div className="wptb-heading">
                            <div className="wptb-item--inner text-center">
                                <h1 className="wptb-item--title">About Us</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-8">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="wptb-image-single wow fadeInUp">
                                            <div className="wptb-item--inner">
                                                <div className="wptb-item--image mt-3">
                                                    <img src="./assets/influencer marketing.png" alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 ps-md-0 mt-5">
                                        <div className="wptb-about--text">
                                            <p className="wptb-about--text-one mb-4">Wiral Tribe empowers brands to amplify their voice through the power of authentic influencer collaborations, driving engagement and fostering lasting connections</p>
                                            <p>Wiral Tribe is an influencer marketing agency that helps brands build meaningful connections through authentic partnerships. We create tailored strategies, connect with the right influencers, and manage campaigns to deliver engaging content and measurable results.</p>
                                            <div className="wptb-item--button round-button">
                                                <a className="btn btn-two" href="#">
                                                    <span className="btn-wrap">
                                                        <span className="text-first">Explore Us</span>
                                                        <span className="text-second"> <i className="bi bi-arrow-up-right"></i> <i className="bi bi-arrow-up-right"></i> </span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row wptb-about-funfact">
                                    <div className="col-md-6 mb-4 mb-md-0">
                                        <div className="wptb-counter1 style1 pd-right-60 wow skewIn">
                                            <div className="wptb-item--inner">
                                                <div className="wptb-item--holder d-flex align-items-center">
                                                    <div className="wptb-item--value"><span className="odometer" data-count="100"></span><span className="suffix">%</span></div>
                                                    <div className="wptb-item--text">Customer Satisfaction</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="wptb-counter1 style1 pd-right-60 wow skewIn">
                                            <div className="wptb-item--inner">
                                                <div className="wptb-item--holder d-flex align-items-center">
                                                    <div className="wptb-item--value flex-shrink-0"><span className="odometer" data-count="350"></span><span className="suffix">+</span></div>
                                                    <div className="wptb-item--text">Influencers</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 ps-xl-5 mt-5 mt-xl-0 d-none d-xl-block">
                                <div className="wptb-image-single wow fadeInUp">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--image mt-3">
                                            <img src="./assets/branding-1.png" alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="wptb-item-layer wptb-item-layer-one">
                            <img src="./assets/assets/img/more/light-1.png" alt="img" />
                        </div>
                        <div className="wptb-item-layer wptb-item-layer-two both-version">
                            <img src="./assets/assets/img/more/light-2.png" alt="img" />
                            <img src="./assets/assets/img/more/light-2-light.png" alt="img" />
                        </div>

                    </div>
                </section>


                <section className="wptb-services-one pd-bottom-80 bg-image-4" style={{backgroundImage:" url('./assets/assets/img/more/texture-3.png')",backgroundPosition:" 50% -16%"}}>
                    <div className="container position-relative">
                        <div className="wptb-heading-two">
                            <div className="wptb-item--inner text-center">
                                <h1 className="wptb-item--title">Industries We Serve  </h1>

                            </div>
                        </div>
                        <div className="row">
                            {/* <!-- Iconbox --> */}
                            <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/healthy-drink.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title">
                                            <Link to={`/Servicescontent/1`}>FMCG</Link>
                                            </h4>
                                        

                                            {/* <!-- <h6 className="wptb-item--count text-outline">01</h6> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                           

                            {/* <!-- Iconbox --> */}
                            <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0 active highlight">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/healthcare.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title">
                                            <Link to={`/Servicescontent/2`}>Health Care</Link></h4>
                                            {/* <!-- <h6 className="wptb-item--count text-outline">02</h6> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Iconbox --> */}
                            <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/property.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title">
                                            <Link to={`/Servicescontent/3`}>Real Estate</Link></h4>
                                            {/* <!-- <h6 className="wptb-item--count text-outline">03</h6> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Iconbox --> */}
                            {/* <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/fast-food.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title"><a href="#">Food and Beverages</a></h4>
                                            <!-- <h6 className="wptb-item--count text-outline">04</h6> --> 
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                             <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/retailer.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title">
                                            <Link to={`/Servicescontent/4`}>Retail and E-commerce</Link></h4>
                                            {/* <!-- <h6 className="wptb-item--count text-outline">01</h6> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Iconbox --> */}
                            <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/education.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title">
                                            <Link to={`/Servicescontent/5`}>Education</Link></h4>
                                            {/* <!-- <h6 className="wptb-item--count text-outline">05</h6> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- Iconbox --> */}
                            <div className="col-md-4 pd-left-25 pd-right-25 wow fadeInLeft">
                                <div className="wptb-icon-box7 mb-0">
                                    <div className="wptb-item--inner">
                                        <div className="wptb-item--icon">
                                            <img src="./assets/smartphone.png" alt="img" />
                                        </div>
                                        <div className="wptb-item--holder">
                                            <h4 className="wptb-item--title">
                                            <Link to={`/Servicescontent/6`}>Entertainment and media</Link></h4>
                                            {/* <!-- <h6 className="wptb-item--count text-outline">06</h6> --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid_lines">
                            <div className="grid_line"></div>
                            <div className="grid_line"></div>
                            <div className="grid_line"></div>
                            <div className="grid_line"></div>
                        </div>
                    </div>
                </section>
                {/* <!-- Text Marquee --> */}
                <div className="wptb-marquee">
                    <div className="wptb-text-marquee1 wptb-slide-to-left">
                        <div className="wptb-item--container">
                            <div className="wptb-item--inner">
                                <h4 className="wptb-item--text text-outline">
                                    <span className="wptb-text-backdrop">influencer</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text">
                                    <span className="wptb-text-backdrop">Wiraltribe</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img"/>
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text">
                                    <span className="wptb-text-backdrop">Marketing</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text text-outline">
                                    <span className="wptb-text-backdrop">Agency</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text">
                                    <span className="wptb-text-backdrop">Wiraltribe</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                            </div>

                            <div className="wptb-item--inner">
                                <h4 className="wptb-item--text text-outline">
                                    <span className="wptb-text-backdrop">influencer</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text">
                                    <span className="wptb-text-backdrop">Marketing</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text text-outline">
                                    <span className="wptb-text-backdrop">Agency</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                                <h4 className="wptb-item--text">
                                    <span className="wptb-text-backdrop">Wiraltribe</span>
                                    <span className="wptb-item-layer both-version position-relative">
                                        <img src="./assets/assets/img/more/star.png" alt="img" />
                                        <img src="./assets/assets/img/more/star-dark.png" alt="img" />
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="wptb-faq-one bg-image pb-0" style={{backgroundImage:" url('./assets/assets/img/background/bg-8.jpg')"}}>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="wptb-heading">
                                    <div className="wptb-item--inner">
                                        <h1 className="wptb-item--title mb-lg-0">Why Choose Us</h1>
                                    </div>
                                </div>

                                <div className="wptb-accordion wptb-accordion2 wow fadeInUp">
                                    <div className="wptb--item active">
                                        <h6 className="wptb-item-title"><span>
                                        What makes Wiraltribe different from other agencies?</span> <i className="plus bi bi-plus"></i> <i className="minus bi bi-dash"></i></h6>
                                        <div className="wptb-item--content">
                                        Wiraltribe offers a combination of specialized industry expertise on a global scale that enables us to dedicate the appropriate time, resource and people to each of our clients. Our unbiased and neutral influencer selection and vetting process sets us apart from talent management agencies and our data-driven insights, paired with 
                                        our human-centric approach, gives each client the personalized experience they deserve.  </div>
                                    </div>

                                    <div className="wptb--item">
                                        <h6 className="wptb-item-title"><span> How do you create the ‘right’ marketing strategy for us?</span> <i className="plus bi bi-plus"></i> <i className="minus bi bi-dash"></i></h6>
                                        <div className="wptb-item--content">
                                       
With years of unique data and insights from a vast array of campaigns executed across global markets, coupled with extensive external research and local market knowledge, every decision we make is grounded in real-world understanding. During the proposal development phase, our insights and strategy teams dedicate considerable time to researching your brand, delving deep into both existing and potential target audiences. This comprehensive understanding, paired with a clear grasp of your business and marketing goals, allows us to craft a tailor-made, authentic, and engaging strategy. Our approach focuses on creating social-first, 
influencer-driven content that resonates with audiences and aligns with your brand’s objectives.</div>
                                    </div>

                                    <div className="wptb--item">
                                        <h6 className="wptb-item-title"><span> Is there a minimum campaign or contract length?</span> <i className="plus bi bi-plus"></i> <i className="minus bi bi-dash"></i></h6>
                                        <div className="wptb-item--content">
                                        Our typical minimum campaign length is three months. For our retained clients, 
                                        we are very flexible, always willing to activate campaigns around specific days or events.  </div>
                                    </div>
                                    <div className="wptb--item">
                                        <h6 className="wptb-item-title"><span>  Why combine influencers and paid media?</span> <i className="plus bi bi-plus"></i> <i className="minus bi bi-dash"></i></h6>
                                        <div className="wptb-item--content">
                                        Our unique method of running paid media through influencer handles allows us to amplify organic content strategies, deliver results towards performance objectives, support your overall digital strategy and ultimately 
                                        drive consumers through the entire sales funnel using laser-accurate targeting. </div>
                                    </div>
                                    <div className="wptb--item">
                                        <h6 className="wptb-item-title"><span> Does influencer Marketing always work?</span> <i className="plus bi bi-plus"></i> <i className="minus bi bi-dash"></i></h6>
                                        <div className="wptb-item--content">
                                        Based on our experience, yes – or at least 99% of the time. Influencer marketing is the best way to get your content in front of the right audience in an authentic and relevant manner, leveraging the power of word of mouth and social proof. We have delivered over 2,000 successful campaigns worldwide, for a variety of brands, offering a diverse range of products and services. Whatever your objective may be, our expert team is able to deliver 
                                        industry-leading results from the top of the funnel to the bottom. </div>
                                    </div>
                                </div>

                                <div className="wptb-agency-experience--item">
                                    <span>15+</span> Years Experience
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="wptb-image-single wow fadeInUp">
                                    <div className="wptb-item--inner">
                                    <div className="wptb-item--image" style={{ marginLeft: "85px" }}>
                                    <img src="./assets/why choose.png" alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="wptb-team-one pd-top-90">
                    <div className="container">
                        <div className="wptb-heading">
                            <div className="wptb-item--inner">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h1 className="wptb-item--title mb-lg-0"> Youtube
                                            Influencers</h1>
                                    </div>

                                    <div className="col-lg-6">
                                        {/* <!-- <p className="wptb-item--description">we’re deeply passionate <span>catch your lovely memories in cameras</span>
                                                                                                                    and Convey your love for every moment of life as a whole.</p> --> */}


                                        {/* <!-- Swiper Navigation --> */}
                                        <div className="wptb-swiper-navigation style1">
                                            <div className="wptb-swiper-arrow swiper-button-prev"></div>
                                            <div className="wptb-swiper-arrow swiper-button-next"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="swiper-container swiper-team">
                            <div className="swiper-wrapper">
                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/3.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Lifestyle Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/18.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Tech Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/6.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Fitness Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/5.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Motivation Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/4.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Travel Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/16.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Vines Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/19.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Education Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/1/18.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Gaming Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Team Block --> */}
                                <div className="swiper-slide">
                                    <div className="wptb-team-grid1">
                                        <div className="wptb-item--inner">
                                            <div className="wptb-item--image">
                                                <img src="./assets/assets/img/projects/5/5.jpg" alt="img" />
                                            </div>

                                            <div className="wptb-item--holder">
                                                <div className="wptb-item--meta">
                                                    <h5 className="wptb-item--title">Vlog Influencers</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <h1 className="wptb-item--title" style={{ textAlign: "center" }}>Testimonial</h1>
                {/* <!-- Testimonial --> */}
                <section className="wptb-testimonial-one testimonial-colored bg-image" style={{backgroundImage:" url('./assets/assets/img/background/bg-16.jpg')"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="swiper-container swiper-testimonial">
                                    {/* <!-- swiper slides --> */}

                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                            <div className="wptb-testimonial1">
                                                <div className="wptb-item--inner">
                                                    <div className="wptb-item--holder">
                                                        <div className="d-flex align-items-center justify-content-between mr-bottom-25">
                                                            <div className="wptb-item--meta-rating">
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                            </div>

                                                            <div className="wptb-item--icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="45" viewBox="0 0 57 45" fill="none">
                                                                    <path d="M51.5137 38.5537C56.8209 32.7938 56.2866 25.3969 56.2697 25.3125V2.8125C56.2697 2.06658 55.9734 1.35121 55.4459 0.823763C54.9185 0.296317 54.2031 0 53.4572 0H36.5822C33.48 0 30.9572 2.52281 30.9572 5.625V25.3125C30.9572 26.0584 31.2535 26.7738 31.781 27.3012C32.3084 27.8287 33.0238 28.125 33.7697 28.125H42.4266C42.3671 29.5155 41.9517 30.8674 41.22 32.0513C39.7913 34.3041 37.0997 35.8425 33.2156 36.6188L30.9572 37.0688V45H33.7697C41.5969 45 47.5678 42.8316 51.5137 38.5537ZM20.5566 38.5537C25.8666 32.7938 25.3294 25.3969 25.3125 25.3125V2.8125C25.3125 2.06658 25.0162 1.35121 24.4887 0.823763C23.9613 0.296317 23.2459 0 22.5 0H5.625C2.52281 0 0 2.52281 0 5.625V25.3125C0 26.0584 0.296316 26.7738 0.823762 27.3012C1.35121 27.8287 2.06658 28.125 2.8125 28.125H11.4694C11.41 29.5155 10.9945 30.8674 10.2628 32.0513C8.83406 34.3041 6.1425 35.8425 2.25844 36.6188L0 37.0688V45H2.8125C10.6397 45 16.6106 42.8316 20.5566 38.5537Z" fill="#D70006" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <p className="wptb-item--description"> “I have an amazing photography session with team
                                                            wiraltribe photography agency, highly recommended.
                                                            They have amazing atmosphere in their studio. Iw’d
                                                            love to visit again”</p>
                                                        <div className="wptb-item--meta">
                                                            <div className="wptb-item--image">
                                                                <img src="./assets/testimonial/img-1.png" alt="img" />
                                                            </div>
                                                            <div className="wptb-item--meta-left">
                                                                <h4 className="wptb-item--title">Rachel Jackson</h4>
                                                                <h6 className="wptb-item--designation">New York</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="wptb-testimonial1">
                                                <div className="wptb-item--inner">
                                                    <div className="wptb-item--holder">
                                                        <div className="d-flex align-items-center justify-content-between mr-bottom-25">
                                                            <div className="wptb-item--meta-rating">
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                            </div>

                                                            <div className="wptb-item--icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="45" viewBox="0 0 57 45" fill="none">
                                                                    <path d="M51.5137 38.5537C56.8209 32.7938 56.2866 25.3969 56.2697 25.3125V2.8125C56.2697 2.06658 55.9734 1.35121 55.4459 0.823763C54.9185 0.296317 54.2031 0 53.4572 0H36.5822C33.48 0 30.9572 2.52281 30.9572 5.625V25.3125C30.9572 26.0584 31.2535 26.7738 31.781 27.3012C32.3084 27.8287 33.0238 28.125 33.7697 28.125H42.4266C42.3671 29.5155 41.9517 30.8674 41.22 32.0513C39.7913 34.3041 37.0997 35.8425 33.2156 36.6188L30.9572 37.0688V45H33.7697C41.5969 45 47.5678 42.8316 51.5137 38.5537ZM20.5566 38.5537C25.8666 32.7938 25.3294 25.3969 25.3125 25.3125V2.8125C25.3125 2.06658 25.0162 1.35121 24.4887 0.823763C23.9613 0.296317 23.2459 0 22.5 0H5.625C2.52281 0 0 2.52281 0 5.625V25.3125C0 26.0584 0.296316 26.7738 0.823762 27.3012C1.35121 27.8287 2.06658 28.125 2.8125 28.125H11.4694C11.41 29.5155 10.9945 30.8674 10.2628 32.0513C8.83406 34.3041 6.1425 35.8425 2.25844 36.6188L0 37.0688V45H2.8125C10.6397 45 16.6106 42.8316 20.5566 38.5537Z" fill="#D70006" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <p className="wptb-item--description"> “I have an amazing photography session with team
                                                            Wiraltribe photography agency, highly recommended.
                                                            They have amazing atmosphere in their studio. Iw’d
                                                            love to visit again”</p>
                                                        <div className="wptb-item--meta">
                                                            <div className="wptb-item--image">
                                                                <img src="./assets/testimonial/img-2.png" alt="img" />
                                                            </div>
                                                            <div className="wptb-item--meta-left">
                                                                <h4 className="wptb-item--title">Helen Jordan</h4>
                                                                <h6 className="wptb-item--designation">Chicago</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="swiper-slide">
                                            <div className="wptb-testimonial1">
                                                <div className="wptb-item--inner">
                                                    <div className="wptb-item--holder">
                                                        <div className="d-flex align-items-center justify-content-between mr-bottom-25">
                                                            <div className="wptb-item--meta-rating">
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                                <i className="bi bi-star-fill"></i>
                                                            </div>

                                                            <div className="wptb-item--icon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="45" viewBox="0 0 57 45" fill="none">
                                                                    <path d="M51.5137 38.5537C56.8209 32.7938 56.2866 25.3969 56.2697 25.3125V2.8125C56.2697 2.06658 55.9734 1.35121 55.4459 0.823763C54.9185 0.296317 54.2031 0 53.4572 0H36.5822C33.48 0 30.9572 2.52281 30.9572 5.625V25.3125C30.9572 26.0584 31.2535 26.7738 31.781 27.3012C32.3084 27.8287 33.0238 28.125 33.7697 28.125H42.4266C42.3671 29.5155 41.9517 30.8674 41.22 32.0513C39.7913 34.3041 37.0997 35.8425 33.2156 36.6188L30.9572 37.0688V45H33.7697C41.5969 45 47.5678 42.8316 51.5137 38.5537ZM20.5566 38.5537C25.8666 32.7938 25.3294 25.3969 25.3125 25.3125V2.8125C25.3125 2.06658 25.0162 1.35121 24.4887 0.823763C23.9613 0.296317 23.2459 0 22.5 0H5.625C2.52281 0 0 2.52281 0 5.625V25.3125C0 26.0584 0.296316 26.7738 0.823762 27.3012C1.35121 27.8287 2.06658 28.125 2.8125 28.125H11.4694C11.41 29.5155 10.9945 30.8674 10.2628 32.0513C8.83406 34.3041 6.1425 35.8425 2.25844 36.6188L0 37.0688V45H2.8125C10.6397 45 16.6106 42.8316 20.5566 38.5537Z" fill="#D70006" />
                                                                </svg>
                                                            </div>
                                                        </div>

                                                        <p className="wptb-item--description"> “I have an amazing photography session with team
                                                            wiraltribe photography agency, highly recommended.
                                                            They have amazing atmosphere in their studio. Iw’d
                                                            love to visit again”</p>
                                                        <div className="wptb-item--meta">
                                                            <div className="wptb-item--image">
                                                                <img src="./assets/testimonial/img-3.png" alt="img" />
                                                            </div>
                                                            <div className="wptb-item--meta-left">
                                                                <h4 className="wptb-item--title">Helen Jordan</h4>
                                                                <h6 className="wptb-item--designation">New York</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- Swiper Navigation --> */}
                                    <div className="wptb-swiper-navigation style1">
                                        <div className="wptb-swiper-arrow swiper-button-prev"></div>
                                        <div className="wptb-swiper-arrow swiper-button-next"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

            {/* <!-- Footer --> */}
            <footer className="footer style1 bg-image-2" style={{backgroundImage:" url('./assets/assets/img/background/bg-5.png')"}}>
                <div className="footer-top">
                    <div className="container">
                        <div className="footer--inner">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                                    <div className="footer-widget">
                                        <div className="footer-nav">
                                        <ul>
                    <li className="menu-item">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <a href='#'>Blog</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Gallery</a>
                    </li>
                    <li className="menu-item">
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 mb-5 mb-md-0 order-1 order-md-0">
                                    <div className="footer-widget text-center">
                                        <div className="logo mr-bottom-55">
                                            <a href="index.html" className=""><img src="./assets/wiraltribe_logo.png" alt="logo" /></a>
                                        </div>

                                        <h6 className="widget-title">Address: </h6>
                                        <p> 3rd floor, C9RP+6RJ Trendz Axis, Vittal Rao Nagar, HITEC City, Hyderabad, Telangana 500081</p>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                                    <div className="footer-widget text-md-end">
                                        <div className="footer-nav">
                                            <ul>
                                                <li className="menu-item"><a href="#"><b style={{color:" #b90808"}}>Influencer Marketing</b></a></li>
                                                <li className="menu-item"><a href="#">Brand Building</a></li>
                                                <li className="menu-item"><a href="#">Strategic Campaigns</a></li>
                                                <li className="menu-item"><a href="#">TVC & Corporate Films</a></li>
                                                <li className="menu-item"><a href="#"></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Footer Bottom Part --> */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="footer-bottom-inner">
                            <div className="copyright">
                                <p><a href="#">WiralTribe</a> Marketing All Rights Reserved</p>
                            </div>
                            <div className="social-box style-oval">
                                <ul>
                                    <li><a href="#" className="bi bi-facebook"></a></li>
                                    <li><a href="#" className="bi bi-instagram"></a></li>
                                    <li><a href="#" className="bi bi-linkedin"></a></li>
                                    <li><a href="#" className="bi bi-behance"></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>




        </div>
  );
};

export default Home;
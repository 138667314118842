import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Contact from './Contactus';
import About from './Aboutus';
import Services from './Services';
import Servicescontent from './Servicescontent';

function App() {
  return (
    <div >
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/Home' element={<Home />}/>
      <Route path='/Contact' element={<Contact />}/>
      <Route path='/About' element={<About />}/>
      <Route path='/Services' element={<Services />}/>
      <Route path="/Servicescontent/:id" element={<Servicescontent />} />

      </Routes></BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import jsonData from "../src/data/data.json";
import logo from "../src/images/wiraltribe_logo.png";
import logo1 from "../src/images/page-header-bg-4.jpg";
import logo2 from "../src/images/circle.png";
import logo4 from "../src/images/bg-5.png";

const ServicesContent = () => {
  const { id } = useParams(); // Extract the ID from the URL
  const item = jsonData.find((item) => item.id === parseInt(id)); // Match item by ID

  useEffect(() => {
    const preloader = document.getElementById("preloader");
    if (preloader) {
      preloader.classList.remove("hide");
      const timer = setTimeout(() => {
        preloader.classList.add("hide");
      }, 1000);

      return () => clearTimeout(timer); // Cleanup timer
    }
  }, []);

  if (!item) {
    return <div>Item not found</div>;
  }

  return (
    <>
      {/* Preloader */}
      <div id="preloader">
        <div className="preloader-inner">
          <div className="spinner">
            <img src={logo} alt="Loading..." />
          </div>
        </div>
      </div>

      {/* Pointer */}
      <div className="pointer bnz-pointer" id="bnz-pointer" />

      {/* Main Header */}
      <header className="header">
        <div className="header-inner">
          <div className="container-fluid pe-0">
            <div className="d-flex align-items-center justify-content-between">
              {/* Logo */}
              <div className="header_left_part d-flex align-items-center">
                <div className="logo">
                  <Link to="/" className="light_logo">
                    <img src={logo} alt="logo" />
                  </Link>
                  <Link to="/" className="dark_logo">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </div>

              {/* Navigation */}
              <div className="header_center_part d-none d-xl-block">
                <div className="mainnav">
                  <ul className="main-menu">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/About">About Us</Link></li>
                    <li><Link to="/Services">Services</Link></li>
                    <li><a href="#">Blog</a></li>
                    <li><Link to="/Contact">Contact</Link></li>
                  </ul>
                </div>
              </div>

              {/* Right Icons */}
              <div className="header_right_part d-flex align-items-center">
                <button
                  type="button"
                  className="mr_menu_toggle wptb-element d-xl-none"
                >
                  <i className="bi bi-list"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Main Wrapper */}
      <main className="wrapper">
        {/* Page Header */}
        <div
          className="wptb-page-heading"
          style={{ backgroundImage: `url(${logo1})` }}
        >
          <div className="wptb-item-layer wptb-item-layer-one">
            <img src={logo2} alt="decor" />
          </div>
          <h2 className="wptb-item--title text-center">{item.title}</h2>
        </div>

        {/* Main Section */}
        <section
          className="wptb-about-one bg-image-4"
          style={{
            backgroundImage: `url(${logo2})`,
            backgroundPosition: "80%",
          }}
        >
          <div className="container">
            <div className="row">
              {/* Left Content */}
              <div className="col-md-6 col-lg-6">
                <img
                  className="wptb-item--image"
                  src={item.image} // Use the URL directly
                  alt={item.title}
                />
              </div>

              {/* Right Content */}
              <div className="col-md-6 col-lg-6">
                <p className="wptb-about--text">{item.description}</p>
                <p>{item.content}</p>
           
              </div>
            </div>
          </div>
        </section>
      </main>
       {/* Footer */}
    <footer
      className="footer style1 bg-image-2"
      style={{ backgroundImage:`url(${logo4})`}}
    >
      <div className="footer-top">
        <div className="container">
          <div className="footer--inner">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                <div className="footer-widget">
                  <div className="footer-nav">
                  <ul>
                    <li className="menu-item">
                      <Link to="/About">About Us</Link>
                    </li>
                    <li className="menu-item">
                      <a href='#'>Blog</a>
                    </li>
                    <li className="menu-item">
                      <a href="#">Gallery</a>
                    </li>
                    <li className="menu-item">
                      <Link to="/Contact">Contact</Link>
                    </li>
                  </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-5 mb-md-0 order-1 order-md-0">
                <div className="footer-widget text-center">
                  <div className="logo mr-bottom-55">
                    <a href="index.html" className="">
                      <img src={logo} alt="logo" />
                    </a>
                  </div>
                  <h6 className="widget-title">Address: </h6>
                  <p>
                    {" "}
                    3rd floor, C9RP+6RJ Trendz Axis, Vittal Rao Nagar, HITEC City,
                    Hyderabad, Telangana 500081
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mb-5 mb-md-0">
                <div className="footer-widget text-md-end">
                  <div className="footer-nav">
                    <ul>
                      <li className="menu-item">
                        <a href="#">
                          <b style={{ color: "#b90808" }}>Influencer Marketing</b>
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Brand Building</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">Strategic Campaigns</a>
                      </li>
                      <li className="menu-item">
                        <a href="#">TVC &amp; Corporate Films</a>
                      </li>
                      <li className="menu-item">
                        <a href="#" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom Part */}
      <div className="footer-bottom">
        <div className="container">
          <div className="footer-bottom-inner">
            <div className="copyright">
              <p>
                <a href="#">WiralTribe</a> Marketing All Rights Reserved
              </p>
            </div>
            <div className="social-box style-oval">
              <ul>
                <li>
                  <a href="#" className="bi bi-facebook" />
                </li>
                <li>
                  <a href="#" className="bi bi-instagram" />
                </li>
                <li>
                  <a href="#" className="bi bi-linkedin" />
                </li>
                <li>
                  <a href="#" className="bi bi-behance" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default ServicesContent;
